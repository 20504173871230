<template>
  <div>
    <div style="width: 100%; height: 100%;" ref="dataLine"></div>
  </div>
</template>

<script>
  import echarts from 'echarts'

  let option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        lineStyle: {
          color: '#243B45'
        }
      }
    },
    legend: {
      icon: 'rect',
      left: 'center',
      bottom: '0',
      itemGap: 60,
      itemWidth: 20,
      itemHeight: 11,
      textStyle: {
        color: '#fff',
      },
      data: ['ADAS', 'DMS'],
    },
    grid: {
      top: '8%',
      left: '2%',
      right: '5%',
      bottom: '10%',
      containLabel: true
    },
    xAxis: [{
      type: 'category',
      axisLine: {
        lineStyle: {
          color: 'rgba(255,255,255,0.6)',
        }
      },
      boundaryGap: true,
      data: []
    }],
    yAxis: [{
      type: 'value',
      axisTick: {
        show: false
      },
      axisLine: {
        lineStyle: {
          color: 'rgba(255,255,255,0.6)',
        }
      },
      axisLabel: {
        textStyle: {
          fontSize: 14
        }
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(255,255,255,0.6)',
          type: 'dotted',
        }
      },
    }],
    series: [{
      name: 'ADAS',
      type: 'line',
      smooth: true,
      lineStyle: {
        normal: {
          width: 2,
          color: 'rgba(192, 153, 255, 1)'
        }
      },
      showSymbol: false,
      areaStyle: {
        normal: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: 'rgba(192, 153, 255, .5)'
          }, {
            offset: 0.8,
            color: 'rgba(192, 153, 255, 0)'
          }], false),
          shadowColor: 'rgba(0, 0, 0, 0.1)',
          shadowBlur: 10,
        }
      },
      itemStyle: {
        normal: {
          color: 'rgba(192, 153, 255, 1)'
        },
        emphasis: {
          color: 'rgba(192, 153, 255, 1)',
          borderColor: '#fff',
          borderWidth: 2
        }
      },
      smooth: false,
      data: []
    },{
      name: 'DMS',
      type: 'line',
      smooth: true,
      lineStyle: {
        normal: {
          width: 2,
          color: 'rgba(255, 204, 153, 1)'
        }
      },
      showSymbol: false,
      areaStyle: {
        normal: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: 'rgba(255, 204, 153, .5)'
          }, {
            offset: 0.8,
            color: 'rgba(255, 204, 153, 0)'
          }], false),
          shadowColor: 'rgba(0, 0, 0, 0.1)',
          shadowBlur: 10,
        }
      },
      itemStyle: {
        normal: {
          color: 'rgba(255, 204, 153, 1)'
        },
        emphasis: {
          color: 'rgba(255, 204, 153, 1)',
          borderColor: '#fff',
          borderWidth: 2
        }
      },
      smooth: false,
      data: []
    }]
  }
  export default {
    props: {
      linesData: {
        type: Object,
        default: function () {
          return {}
        }
      }
    },
    data () {
      return {}
    },
    watch: {
      "linesData": {
        handler() {
          this.initLine()
        },
        deep: true
      }
    },
    methods: {
      initLine () {
        let dataLine = echarts.init(this.$refs.dataLine)
        if (this.linesData.xData.length===0) return
        option.xAxis[0].data = this.linesData.xData
        option.series[0].data = this.linesData.ADASData
        option.series[1].data = this.linesData.DMSData
        dataLine.setOption(option)
        window.addEventListener('resize',function () {
          dataLine.resize()
        })
      }
    },
    mounted () {
      // this.initLine()
    }
  }
</script>

<style lang='sass' scoped>

</style>
